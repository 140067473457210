const ENV_LOCAL = {
  FRONT: "http://localhost:3000",
  API: "http://localhost:8001",
  TOKEN: "6281c03cb8d942088041f6f7e4852fbf",
};

const ENV_PPD = {
  FRONT: "https://preprod.kc.plateforms.tech",
  API: "https://preprod.api.kc.plateforms.tech",
  TOKEN: "29ef06bf77e04032884722d5c9052512",
};

const ENV_PROD = {
  FRONT: "https://kc.plateforms.tech",
  API: "https://api.kc.plateforms.tech",
  TOKEN: "69fc4f573b794d93b586c8ea7d24eef0",
};

const config =
  process.env.REACT_APP_ENV === "PREPROD" ? ENV_PPD : process.env.REACT_APP_ENV === "PROD" ? ENV_PROD : ENV_LOCAL;

export default config;
