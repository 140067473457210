import React, { useCallback, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EuroIcon from "@material-ui/icons/Euro";

import ContractTemplatesService from "../../Services/ContractTemplatesService";
import { mockedData } from "../../Services/ContractService";

const generateClient = index => ({
  firstname: `Prénom du client ${index}`,
  lastname: `Nom du client ${index}`,
  birthday: `Date de naissance du client ${index}`,
  birthplace: `Lieu de naissance du client ${index}`,
  phone: "0606060606",
  maidenname: `Nom de naissance du client ${index}`,
  mail: `email.client${index}@domain.com`,
  nationality: `Nationnalité du client ${index}`,
  job: `Emploi du client ${index}`,
});

export default function PreviewContractTemplateDialog({ handleClose, contractTemplate }) {
  const [processing, setProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [clientsCount, setClientsCount] = useState(1);
  const [withLoan, setWithLoan] = useState(true);

  const handleRentalVacancy = (event, newValue) => {
    setClientsCount(parseInt(newValue, 10));
  };

  const handleLoan = (event, newValue) => {
    setWithLoan(Boolean(newValue));
  };

  const previewContractTemplate = useCallback(() => {
    setProcessing(true);
    const data = { ...mockedData };
    data.client.pinel_inter = true;
    data.client.pinel = true;
    data.client.options.has_rental_vacancy = true; // No rental in preview
    data.client.lot.rent = "500,00 €";
    data.client.financial_plan.no_loan_signatory = "Nom et prénom pour la décharge";
    data.client.client_1 = generateClient(1);
    data.client.client_2 = clientsCount === 2 ? generateClient(2) : undefined;
    data.client.financial_plan.without_loan = !withLoan;
    ContractTemplatesService.preview(contractTemplate.id, { ...data })
      .then(res => {
        const _blob = new Blob([res.data], { type: "application/pdf" });
        fileDownload(_blob, "contrat.pdf");
        setProcessing(false);
      })
      .catch(e => {
        enqueueSnackbar("Une erreur est survenue", { variant: "error" });
        setProcessing(false);
      });
  }, [contractTemplate, enqueueSnackbar, clientsCount, withLoan]);

  return (
    <Dialog onClose={handleClose} open={!!contractTemplate}>
      <DialogTitle>Personnalisation du contexte de la prévisualisation</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <ToggleButtonGroup
          style={{ marginBottom: 16 }}
          size="small"
          value={clientsCount}
          exclusive
          onChange={handleRentalVacancy}
        >
          <ToggleButton style={{ flex: 2 }} value={1}>
            <PersonIcon fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              Un client
            </Typography>
          </ToggleButton>
          <ToggleButton style={{ flex: 2 }} value={2}>
            <PeopleIcon fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              Deux clients
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup size="small" value={withLoan} exclusive onChange={handleLoan} style={{ marginBottom: 8 }}>
          <ToggleButton style={{ flex: 2 }} value={true}>
            <AccountBalanceIcon fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              Avec financement
            </Typography>
          </ToggleButton>
          <ToggleButton style={{ flex: 2 }} value={false}>
            <EuroIcon fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 8 }}>
              Sans financement
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" color="primary" disabled={processing} onClick={previewContractTemplate}>
          Générer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
